import React, { useState } from 'react';
import DeviceManager from '../../components/DeviceManager';
import AdbManager from '../../components/AdbManager';
import WebSocketComponent from '../../components/Websocket';

const RootPAGE = () => {
    const [device, setDevice] = useState(null);
    const [connection, setConnection] = useState(null);

    return (
        <div>
            <h1>ADB Device Manager</h1>
            <DeviceManager
                onDeviceConnected={setDevice}
                onConnectionEstablished={setConnection}
            />
            {device && connection && (
                <div>
                    <AdbManager device={device} connection={connection} />
                </div>
            )}
            <WebSocketComponent/>
        </div>
    );
};

export default RootPAGE;
