import React, { useState } from 'react';
import { handleRequestDevice } from '../handlers/handleRequestDevice';
import { useConnectDevice } from '../hooks/useConnectDevice';

const DeviceManager = ({ onDeviceConnected, onConnectionEstablished }) => {
    const [device, setDevice] = useState(null);
    const { connect } = useConnectDevice(); 

    const requestDevice = async () => {
        const selectedDevice = await handleRequestDevice(onDeviceConnected, connect, onConnectionEstablished);
        if (selectedDevice) {
            setDevice(selectedDevice);
            console.log(selectedDevice)
        }
    };


    return (
        <div>
            {!device &&(

                <button onClick={requestDevice}>Подключиться к ADB интерфейсу</button>
            )}
            {device && (
                <div>
                    <h2>Подключенное устройство:</h2>
                    <strong>Имя устройства:</strong> {device.raw.productName || 'Неизвестно'} <br />
                    <strong>Производитель:</strong> {device.raw.manufacturerName || 'Неизвестно'} <br />
                    <strong>Серийный номер:</strong> {device.raw.serialNumber || 'Неизвестно'} <br />
                </div>
            )}
        </div>
    );
};

export default DeviceManager;
