import { useState } from 'react';

/**
 * Хук для использования ADB команд
 * @param {Object} adb - ADB объект
 * @returns {{ adbCommand: function }}
 */
export const useAdbCommand = (adb) => {
    const [commandOutput, setCommandOutput] = useState('');

    const adbCommand = async (command) => {
        if (!adb) {
            console.error("ADB не инициализирован.");
            return 'ADB не инициализирован';
        }
        try {
            const process = await adb.subprocess.spawn([command]);
            const reader = process.stdout.getReader();
            let output = '';
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                output += new TextDecoder().decode(value);
            }
            setCommandOutput(output);  // Обновляем состояние для возможного использования в компоненте
            return output;  // Возвращаем результат выполнения команды
        } catch (error) {
            console.error("Ошибка в ADB команде:", error);
            const errorMessage = `Ошибка в ADB команде: ${error.message}`;
            setCommandOutput(errorMessage);
            return errorMessage;  // Возвращаем ошибку
        }
    };

    return {
        adbCommand,
    };
};
