import { AdbDaemonWebUsbDeviceManager } from "@yume-chan/adb-daemon-webusb";
/**
 * Подключение к устройству через WebUSB.
 * @param {Object} manager 
 * @param {Function} onDeviceConnected 
 * @param {Function} connect 
 * @param {Function} onConnectionEstablished
 * @returns {Promise<void>}
 */
export const handleRequestDevice = async (onDeviceConnected, connect, onConnectionEstablished) => {
    const manager = AdbDaemonWebUsbDeviceManager.BROWSER;
    if (!manager) {
        console.error("WebUSB is not supported in this browser.");
        return null;
    }

    try {
        const selectedDevice = await manager.requestDevice({
            filters: [
                {
                    classCode: 0xff,   
                    subclassCode: 0x42, 
                    protocolCode: 1,   
                },
            ],
        }); // Фильтры WEBUSB

        if (!selectedDevice) {
            console.log("Не выбрано ни одно устройство - handleRequestDevice");
            return null;
        }

        onDeviceConnected(selectedDevice);
        const conn = await connect(selectedDevice);
        onConnectionEstablished(conn);

        return selectedDevice;

    } catch (error) {
        if (error.name === "NotFoundError") {
            console.error("Выбор устройства отменен - handleRequestDevice");
        } else {
            console.error("Ошибка handleRequestDevice: ", error);
        }
        return null;
    }
};
