import { useEffect, useRef, useState } from 'react';

const useConnectionWebsocket = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const wsRef = useRef(null);

  useEffect(() => {
    // Создание подключения к WebSocket
    wsRef.current = new WebSocket('wss://backadb.plutus-fin.ru/ws');


    wsRef.current.onopen = () => {
        console.log('WebSocket соединение установлено');
        setIsConnected(true);
      };
    
      wsRef.current.onmessage = (event) => {
        console.log('Получено сообщение:', event.data);
    
        if (typeof event.data === 'string') {
          // Если данные типа строка, добавляем их в сообщения
          setMessages((prevMessages) => [...prevMessages, event.data]);
        } else if (event.data instanceof Blob) {
          // Если данные типа Blob, читаем их как текст
          const reader = new FileReader();
          reader.onload = () => {
            setMessages((prevMessages) => [...prevMessages, reader.result]);
          };
          reader.readAsText(event.data);
        }
      };
    
      wsRef.current.onerror = (error) => {
        console.error('Ошибка WebSocket:', error);
      };
    
      wsRef.current.onclose = () => {
        console.log('WebSocket соединение закрыто');
        setIsConnected(false);
      };
    
      return () => {
        if (wsRef.current) {
          wsRef.current.close();
        }
      };
    }, []);

  // Функция для отправки сообщений через WebSocket
  const sendMessage = (message) => {
    if (wsRef.current && isConnected) {
      wsRef.current.send(message);
    } else {
      console.error('WebSocket не подключен');
    }
  };

  return { isConnected, messages, sendMessage };
};

export default useConnectionWebsocket;
