import { useState } from 'react';

/**
 * Хук для установления соединения с устройством.
 * @returns {Object}
 */

export const useConnectDevice = () => {
    const [connection, setConnection] = useState(null);
    const [error, setError] = useState(null);

    /**
     * Подключение к устройству.
     * @param {Object} device - Устройство
     * @returns {Promise<void>}
     */
    const connect = async (device) => {
        try {
            console.log(device);
            const connection = await device.connect();
            setConnection(connection);
            return connection;
        } catch (error) {
            if (error.name === "NetworkError") {
                alert("The device is already in use by another program.");
            }
            setError(error);
            throw error;
        }
    };

    return {
        connection,
        error,
        connect,
    };
};
