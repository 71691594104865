import React, { useState, useEffect } from 'react';
import { useAdbCommand } from '../hooks/useAdbCommand';
import { useAuthDevice } from '../hooks/useAuthDevice';

const AdbManager = ({ device, connection }) => {
    const [command, setCommand] = useState('');
    const [showAdbContent, setShowAdbContent] = useState(false);
    const [showAdbDiagContent, setShowAdbDiagContent] = useState(false);
    const [ssid, setSsid] = useState('Загрузка...');
    const [webview, setWebview] = useState('Загрузка...');
    const [serts, setserts] = useState('Загрузка...');
    const [Keyboard, setKeyboard] = useState('Загрузка...');
    const [BuildVersion, setBuildVersion] = useState('Загрузка...');
    const [RootD, setRootD] = useState('Загрузка...');
    const [ScreenSize, setScreenSize] = useState('Загрузка...');


    const [outputCom, setoutputCom] = useState(''); 

    const { adb, isAuthenticated, authenticateWithDevice } = useAuthDevice(device, connection);
    const { adbCommand } = useAdbCommand(adb); // Используем adbCommand как единую функцию

    const handleCommandSubmit = async (e) => {
        e.preventDefault();
        const output = await adbCommand(command); // Отправляем команду и получаем результат
        console.log('Результат команды:', output);
        setoutputCom(output);
    };

    const handleAdbButtonClick = () => {
        setShowAdbContent(true);
        setShowAdbDiagContent(false);
    };

    const handleAdbDiagButtonClick = () => {
        setShowAdbDiagContent(true);
        setShowAdbContent(false);
    };

    // console.log(isAuthenticated)

    const fetchSsid = async () => {
        const output = await adbCommand('dumpsys wifi | grep SSID'); // Отправляем команду
        if (output) {
            const ssidMatch = output.match(/SSID: (\S+)/);
            if (ssidMatch && ssidMatch[1]) {
                setSsid(ssidMatch[1]);
            } else {
                setSsid('Нет данных');
                console.error('Не удалось найти SSID в выводе команды.');
            }
        }
    };

    const fetchWebview = async () => {
        const output = await adbCommand('dumpsys package com.google.android.webview | grep versionName'); // Отправляем команду
        if (output) {
            const versionMatch = output.match(/versionName=(\S+)/);
            if (versionMatch && versionMatch[1]) {
                setWebview(versionMatch[1]);
            } else {
                setWebview('Нет данных');
                console.error('Не удалось найти версию WebView в выводе команды.');
            }
        }
    };

    const fetchSerts = async () => {
        const output = await adbCommand('cat /system/etc/security/cacerts/*'); // Отправляем команду
        const certLines = output.split('\n');
        const amazonCertsSet = new Set();
        certLines.forEach(line => {
            if (line.includes('O=Amazon')) {
                const certName = line.match(/CN=([^\\r]+)/);
                if (certName) {
                    amazonCertsSet.add(certName[1].trim());
                }
            }
        });
        const count = amazonCertsSet.size;
        console.log("🚀 ~ fetchSerts ~ count:", count)
        setserts(count);
    };

    const fetchKeyboard = async () => {
        const output = await adbCommand('dumpsys package com.anydesk.anydeskandroid | grep versionName'); // Отправляем команду
        if (output) {
            const versionMatch = output.match(/versionName=(\S+)/);
            if (versionMatch && versionMatch[1]) {
                setKeyboard(versionMatch[1]);
            } else {
                setKeyboard('Нет данных');
                console.error('Не удалось найти версию клавитуру в выводе команды.');
            }
        }
    };

    const fetchBuildVersion = async () => {
        const output = await adbCommand('getprop ro.build.version.incremental'); // Отправляем команду
        if (output) {
            setBuildVersion(output);
        } else {
            setBuildVersion('Нет данных');
        }
    };

    const fetchRoot = async () => {
        const output = await adbCommand('su -c id'); 
        if (output) {
            setRootD(`Найдены ROOT права ${output}`);
        } else {
            setRootD('ROOT прав не обнаруженно');
        }
    };

    const fetchScreen = async () => {
        const output = await adbCommand('wm size'); // Отправляем команду
        if (output) {
           setScreenSize(output)
        } else{
            setScreenSize(`Нет данных`)
        }
    };



    useEffect(() => {
        if (isAuthenticated && showAdbDiagContent) {
            fetchSsid();
            fetchWebview();
            fetchSerts();
            fetchKeyboard();
            fetchBuildVersion();
            fetchRoot();
            fetchScreen();
        }
    }, [isAuthenticated, showAdbDiagContent]);

    const testResults = [
        { test: '🟦 SSID сети:', result: ssid },
        { test: '🟦 Webview:', result: webview },
        { test: '🟦 Корневые сертификаты', result: serts },
        { test: '🟦 Клавиатура виртуальная', result: Keyboard },
        { test: '🟦 Прошивка', result: BuildVersion },
        { test: '🟦 Рут права', result: RootD },
        { test: '🟦 Разрешение экрана', result: ScreenSize },
    ];

    return (
        <div>
            {!isAuthenticated&&(

                <button onClick={authenticateWithDevice}>Инициализировать интерфейс</button>
            )}
            {isAuthenticated && (
                <div>
                    <button onClick={handleAdbButtonClick}>ADB команды</button>
                    <button onClick={handleAdbDiagButtonClick}>ADBDIAG</button>
                </div>
            )}

            {isAuthenticated && showAdbContent && (
                <div>
                    <h3>ADB Initialized</h3>

                    <form onSubmit={handleCommandSubmit}>
                        <input
                            type="text"
                            value={command}
                            onChange={(e) => setCommand(e.target.value)} // Обновляем команду
                            placeholder="Введите ADB команду"
                        />
                        <button type="submit">Отправить команду</button>
                    </form>
                    <div className="out">
                        <strong>Результат</strong>
                        <pre>{outputCom}</pre>
                    </div>
                </div>
            )}

            {isAuthenticated && showAdbDiagContent && (
                <div>
                    <h1>ADB Diag</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Тест</th>
                                <th>Результат</th>
                            </tr>
                        </thead>
                        <tbody>
                            {testResults.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.test}</td>
                                    <td>{item.result}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default AdbManager;
