// хук для авторизации адб на уст-ве
import { useState } from 'react';
import { AdbDaemonTransport, Adb } from "@yume-chan/adb";
import AdbWebCredentialStore from "@yume-chan/adb-credential-web";

/**
 * Хук для авторизации устройства через ADB
 * @param {Object} device - Устройство
 * @param {Object} connection - Подключение к устройству
 * @returns {{adb: Adb|null, isAuthenticated: boolean, authenticateWithDevice: () => Promise<void>}}
 */
export const useAuthDevice = (device, connection) => {
    console.log("🚀 ~ useAuthDevice ~ connection:", connection)
    console.log("🚀 ~ useAuthDevice ~ device:", device)
    const [adb, setAdb] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const authenticateWithDevice = async () => {
        try {
            const credentialStore = new AdbWebCredentialStore();
            const transport = await AdbDaemonTransport.authenticate({
                serial: device.raw.serialNumber,
                connection,
                credentialStore,
            });
            setAdb(new Adb(transport));
            setIsAuthenticated(true);
            console.log(adb)
        } catch (error) {
            console.error("Authentication failed:", error);
            setIsAuthenticated(false);
        }
    };

    return {
        adb,
        isAuthenticated,
        authenticateWithDevice,
    };
};
