import React, { useState } from 'react';
import useConnectionWebsocket from '../hooks/useWebsocketConnect';

const WebSocketComponent = () => {
  const { isConnected, messages, sendMessage } = useConnectionWebsocket();
  const [inputMessage, setInputMessage] = useState('');

  const handleSendMessage = () => {
    sendMessage(inputMessage);
    setInputMessage('');
  };

  return (
    <div>
      <h1>WebSocket Connection</h1>
      <p>Статус соединения: {isConnected ? 'Подключен' : 'Отключен'}</p>
      
      <div>
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Введите сообщение"
        />
        <button onClick={handleSendMessage} disabled={!isConnected}>
          Отправить
        </button>
      </div>
      
      <h2>Полученные сообщения:</h2>
      <ul>
        {messages.map((msg, index) => (
          <li key={index}>{msg}</li>
        ))}
      </ul>
    </div>
  );
};

export default WebSocketComponent;
